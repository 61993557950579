<template>
  <!-- 信链融资管理 -->
  <div class="page-card-demo  page-info-content">
    <!-- 搜索筛选 -->
    <FormSearch
      :getdata="getDate"
      :form-inline="formInline"
      :form-item-arr="formItemArr"
    >
      <el-form-item label="融资申请日" label-width="200px">
        <el-date-picker
          v-model="dateRange"
          type="daterange"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd HH:mm:ss"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="getDate"
        />
      </el-form-item>
      <el-form-item label="融资到期日" label-width="200px">
        <el-date-picker
          v-model="dateRange"
          type="daterange"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd HH:mm:ss"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="getDate"
        />
      </el-form-item>
    </FormSearch>
    <div>
      <Table
        :item-data="itemData"
        :list-data="listData"
        :operation-button="operationButton"
        :loading="loading"
      >
        <template #appStat="{row}">
          {{ $codeNew(echainFinanceState,row.appStat) }}
        </template>
        <!-- <template #status="{ row }">
          <span>{{ row.status === 0 ? '进行中' : '已下架' }}</span>
          <span class="reason">{{ row.reason === 1 ? '(售出下架)' : row.reason === 2 ? '(超时下架)' : row.reason === 3 ? '(主动下架)': '' }}</span>
        </template> -->
      </Table>
      <!-- 分页 -->
      <Pagination :params="formInline" :total="total" :in-article="listData.length" :get-data-list="getDate" />
    </div>
    <agreementListDialog ref="agreementListDialog" :echain-id="echainId" />
    <financingProgressDialog
      ref="financingProgressDialog"
      :echain-id="echainId"
    />
  </div>
</template>

<script>
import FinancingProgressDialog from './financingProgressDialog.vue'
import AgreementListDialog from './agreementListDialog.vue'
import { selectFinanceList } from '@/api/tradeFinancing.js'
import FormSearch from '@/components/FormSearch'
import Pagination from '@/components/Pagination2'
import Table from '@/components/Table.vue'
export default {
  components: { FormSearch, Pagination, Table, AgreementListDialog, FinancingProgressDialog },
  data() {
    return {
      echainId: 0,
      formInline: {

      },
      echainFinanceState: this.$store.getters.getDictionaryItem('ECHAIN_FINANCE_STATE'),
      formItemArr: [
        { type: 'input', label: '融资编号', value: 'echainFinanceNo', width: '120px' },
        { type: 'input', label: '信链编号', value: 'echainNo', width: '80px' },
        { type: 'select', label: '融资状态', value: 'appStat', pLabel: 'dictName', pValue: 'dictId', child: this.$store.getters.getDictionaryItem('ECHAIN_FINANCE_STATE') }
      ],
      itemData: [
        { label: '融资编号', prop: 'echainFinanceNo', width: 120 },
        { label: '信链编号', prop: 'echainNo', width: 120 },
        { label: '融资金额', prop: 'appAmt', width: 280 },
        { label: '金融机构', prop: 'fundCmpName', width: 180 },
        { label: '申请企业', prop: 'cmpName', width: 180 },
        { label: '买方名称', prop: 'buyCmpName', width: 180 },
        { label: '融资申请日', prop: 'applayDate', width: 180 },
        { label: '融资到期日', prop: 'appDate', width: 180 },
        { label: '融资状态', prop: 'appStat', width: 180 }
      ],
      operationButton: [
        { bType: 'primary', label: '查看详情', handleEvent: this.viewDetails },
        { bType: 'primary', label: '协议详情', handleEvent: this.handleLookAgreement, child: [{ val: 'agreementBtn' }] },
        { bType: 'primary', label: '融资进度', handleEvent: this.handelLookProgress }
      ],
      listData: [],
      total: 0,
      loading: false,
      dateRange: []
    }
  },
  methods: {
    getDate(params) {
      this.loading = true
      this.formInline = {
        ...this.formInline,
        ...params
      }
      selectFinanceList(this.formInline, res => {
        this.loading = false
        this.listData = res.data.list.map(item => {
          if (item.appStat === '04' || item.appStat === '05' || item.appStat === '06' ||
          item.appStat === '07' || item.appStat === '08' || item.appStat === '09' || item.appStat === '12' || item.appStat === '13' || item.appStat === '14') {
            item.agreementBtn = true
          }
          return item
        })
        this.total = res.data.total
      }, () => {
        this.loading = false
      })
    },
    viewDetails(row) {
      this.$router.push({
        name: 'echainFinanceDetail',
        query: {
          id: row.id,
          type: '1',
          echainFinanceNo: row.echainFinanceNo
        }
      })
    },
    handleLookAgreement(row) {
      this.echainId = row.id
      this.openDialog('agreementListDialog')
    },
    handelLookProgress(row) {
      this.echainId = row.id
      this.openDialog('financingProgressDialog')
    },
    openDialog(dialogName) {
      this.$refs[dialogName].dialogVisible = true
    }
  }
}
</script>

<style>

</style>
